<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero" data-flip-id="flip" ref="hero">
					<figure>Corporate social responsibility</figure>
					<h1>Advancing  STEM Education  Through Our Rising Stars</h1>
					<p>While the pandemic disrupted how we worked in 2020, we remained committed to successfully executing our corporate social responsibility strategy and are proud of the continued progress we made in advancing our support of STEM education.</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<div class="body-columns">
						<div class="body-columns__content">

							<h2 style="margin-top: 0">Dtcc <span>rising stars</span></h2>

							<p>We launched our DTCC Rising Stars program,  our latest initiative  to support the training and development of CSR program alumni.   Our first group of Rising Stars featured 64  high school students who complete programs with organizations as diverse as Girls Who Code, NYU CS4CS, PENCIL,  Jersey City Summer Internship  and Boston Private Industry Council.  </p>

							<p>The students  benefit from bi-monthly sessions  focused  on DTCC businesses, hackathons, coding challenges, mentoring and other educational activities. During 2020,  we hosted three modules on the Lifecycle of a Security,  a  JAVA coding session  and User Experience and Design workshop.   </p>   

							<p>Through this program, we will be  engaging with the  alumni  over several years, providing them with training, education and exposure to senior leaders. In turn,  DTCC Rising Stars will help our company develop and maintain relationships with a pipeline of diverse candidates with the goal of strengthening the talent pool for our Summer Internship and EDGE Programs in both our Non-IT and IT departments.</p>
						</div>

						<div class="body-columns__images">
							<img src="~@/assets/csr/stemImg1@2x.jpg" width="340" />
						</div>
					</div>
				</div>

				<div class="body-quote">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--left">
						<div class="body-quote__quote">
							“Every student walked away from  the Rising Star Coder event  smiling  because they found  it  challenging  and, thanks to the help of their DTCC coaches,  were able to successfully work through the obstacles. It’s so important to teach students  problem-solving skills and that collaboration and  failing fast  and  are  keys  to success. Those lessons will serve them well in school and in their professional lives.”
							<div class="body-quote__credit">— <strong>Dana Atherton</strong>,  Associate Director </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<h2>STEM Education <span>Around the World</span></h2>

					<boxes>
						<template v-slot:box1>
							<h3><img src="~@/assets/csr/stemIcon1.svg" > Chennai Youth Leadership Program    </h3>
							<p>Enables young students to develop their communication and leadership skills through practical experience. 8  to 10 week program from  Toastmasters International  for middle school students assisted by DTCC mentors.  </p>
						</template>

						<template v-slot:box2>
							<h3><img src="~@/assets/csr/stemIcon2.svg" > Chennai Young Women in Coding Development Program    </h3>
							<p>Fifteen  female students aged 19-20  graduated from the first Young Women in Coding and Development  Program  consisted of a twelve-week program run  by DTCC    </p>
						</template>

						<template v-slot:box3>
							<h3><img src="~@/assets/csr/stemIcon3.svg" > Continued Partnership with NYU’s CS4CS    </h3>
							<p>We partnered with the NYU Tandon’s Center for K12 STEM Education for the third year to sponsor high school students in a 3-week immersive Computer Science for Cyber Security (CS4CS)  summer program.</p>
						</template>

						<template v-slot:box4>
							<h3><img src="~@/assets/csr/stemIcon4.svg" > Creating Virtual STEM Education Programs</h3>
							<p>Thanks to over 120 DTCC volunteers, we transitioned our summer STEM programs for high school students to a virtual format. We hosted 66 Girls Who Code students for a Summer Immersion Program, taught cyber security topics and provided mentoring to New York University Computer Science for Cybersecurity Camp (NYU CS4CS) and welcomed three PENCIL interns visually.</p>
						</template>
					</boxes>
				</div>
			</template>

			<template v-slot:footer>

				<article-next class="csr__next" :image="'csr/bgAction@2x.jpg'" :link="{name: 'csr', params: {
					article: 'action'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Corporate Social Responsibility</template>
					<template v-slot:title>CSR in Action</template>
				</article-next>

			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Stem',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {

	&__hero {
		background-image: url('~@/assets/csr/bgStem@2x.jpg');
	}

	.body-quote {
		background-image: url('~@/assets/csr/bgStemQuote@2x.jpg');
	}
}

</style>
