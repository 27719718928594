<template>
	<div class="boxes">
		<div class="boxes__box">
			<slot name="box1">[box1]</slot>
		</div>
		<div class="boxes__box">
			<slot name="box2">[box2]</slot>
		</div>
		<div class="boxes__box">
			<slot name="box3">[box3]</slot>
		</div>
		<div class="boxes__box">
			<slot name="box4">[box4]</slot>
		</div>
	</div>
</template>

<script>


export default {
	name: 'boxes'
}
</script>

<style scoped lang="scss">
.boxes {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@include media-query-min-width('tablet') {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		//grid-template-columns: 1fr auto;
		grid-auto-rows: 1fr 1fr;
		grid-gap: 16px;
		margin-bottom: 0;
	}

	&__box {
		@include gutter('padding', 1.5);
		background: #fff;
		box-shadow: 0 0 24px rgba(0, 0, 0, .16);
		border-radius: 20px;
		height: 100%;
		margin-bottom: 16px;
		width: 100%;

		@include media-query-min-width('tablet') {
			margin-bottom: 0px;
		}

		.letters &,
		.csr & {
			p {
				@include gutter('margin-bottom', 1);
				@include fontSize(16px);
			}

			h2 {
				margin-top: 0;
			}

			h3 {
				//@include fontSize(18px);
				display: flex;
				align-items: center;
				font-weight: bold;
				line-height: 1.25;
				margin-top: 0;

				img {
					margin-right: 20px;
				}
			}
		}
	}
}
</style>
